import type { FC } from "react";
import React, { useEffect } from "react";
import {
  IconCheckmarkRoundGreenBackground,
  IconInfoRhombusYellowBackground
} from "src/constants/icons";
import translate from "src/lib/translate";
import HealthSyncBloc from "src/state/HealthSyncBloc/HealthSyncBloc";
import { toast, useBloc } from "src/state/state";
import AppPageTitle from "../AppPageTitle/AppPageTitle";
import { PROFILE_URL, ProfileMenuItem } from "../ProfileOverview/ProfileOverview";
import Collection from "../StyledComponents/Collection";
import CollectionItemLink from "../StyledComponents/CollectionItemLink";
import OverlayBackButton from "../StyledComponents/OverlayBackButton";
import {
  AppPopup,
  AppQueryPopupsController
} from "../AppQueryPopups/AppQueryPopupsBloc";
import { Document, getLegalDocumentLink } from "src/lib/getLegalDocumentLink";

const DataSync: FC = () => {
  const [
    { stepsAuthorized, stepsData },
    { isAvailable, requestAuthorization, checkAndSync }
  ] = useBloc(HealthSyncBloc);
  const allZero = stepsData.every((step) => step.value === 0);

  const handleAuthCheck = () => {
    if (!isAvailable) {
      toast.show("health.syncNotAvailable");
      return;
    }

    if (allZero) {
      toast.show("health.noDataCheckPermission");
    }

    if (stepsAuthorized) {
      void requestAuthorization();
      return;
    }

    AppQueryPopupsController.openPopup(AppPopup.healthSyncSetup);
  };

  const handlePrivacyClick = () => {
    AppQueryPopupsController.openPopup(AppPopup.iframe, {
      additionalParameters: {
        url: getLegalDocumentLink(Document.noticePrivacyPractice),
        title: translate("registerConsent.noticePrivacyPractice"),
        stay: "false"
      }
    });
  };

  useEffect(() => {
    void checkAndSync();
  }, []);

  return (
    <>
      <OverlayBackButton show fallbackUrl={PROFILE_URL} />
      <AppPageTitle title={translate("dataSync")}></AppPageTitle>
      <nine-spacer s={"xl"}></nine-spacer>
      <Collection>
        <CollectionItemLink
          label={translate("dataSync.appleHealthPermissions")}
          onClick={handleAuthCheck}
          endIcon={
            stepsAuthorized && !allZero ? (
              <IconCheckmarkRoundGreenBackground />
            ) : (
              <IconInfoRhombusYellowBackground />
            )
          }
        />
        <CollectionItemLink
          label={translate("dataSync.learnMore")}
          to={PROFILE_URL + ProfileMenuItem.dataSyncLearnMore}
        />
      </Collection>

      <Collection>
        <CollectionItemLink
          label={translate("registerConsent.noticePrivacyPractice")}
          onClick={handlePrivacyClick}
        />
      </Collection>
    </>
  );
};

export default DataSync;
